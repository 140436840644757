<template>
  <div v-show="!isHidden" :style="'height:' + height + ';width:' + width + ';' + CSS">
    <div
      v-if="!isEditor()"
      v-html="renderedHTML"
      class="ck-content"
      style="padding: 0 var(--ck-spacing-standard);"
    ></div>

    <ckeditor
      v-else
      :editor="editor"
      style="height: 100%;"
      ref="editor"
      v-model="localModel"
    ></ckeditor>

    <slot></slot>
  </div>
</template>

<script>
import mixin from '../mixins'
import VisibleMixin from '../visible_properties_mixin'

import CKEditor from '@ckeditor/ckeditor5-vue2'
import Editor from '@/components/HTMLEditor/ckeditor.js'
import CKEditorMixin from '@/components/InterfaceEditor/components/CKEditorMixin'

export default {
  name: 'a-html',
  components: {
    ckeditor: CKEditor.component
  },
  inject: {
    isEditor: {
      default: () => false
    },
    forceUpdateSettingsPanel: {
      default: () => () => {
      }
    }
  },
  mixins: [VisibleMixin, mixin, CKEditorMixin],
  props: {
    editorAlias: {
      type: String,
      description: 'Псевдоним'
    },
    height: {
      description: 'Высота',
      type: String
    },
    width: {
      description: 'Ширина',
      type: String
    },
    html: {
      type: String,
      default: 'HTML text',
      frozen: true
    }
  },
  data () {
    return {
      localModel: this.html,
      editor: Editor
    }
  },
  watch: {
    localModel (value) {
      if (this.isEditor()) {
        this.$emit('change-property', { name: 'html', value })
      }
    },
    editorAlias () {
      this.forceUpdateSettingsPanel()
    }
  },
  mounted () {
    if (this.isEditor()) {
      return false
    }
  },
  computed: {
    renderedHTML () {
      if (this.isEditor()) {
        return null
      }
      let attributes = this.html.match(/\{{(.*?)\}}/g) || []
      let result = this.html
      attributes.forEach((attribute) => {
        attribute = attribute.replace('{{', '').replace('}}', '')
        let value
        if (attribute.indexOf(':date') !== -1) {
          if (this.getRawData() && typeof this.getRawData()[attribute.replace(':date', '')] !== 'undefined') {
            value = this.getRawData()[attribute.replace(':date', '')]
          } else if (this.getModel() && typeof this.getModel()[attribute.replace(':date', '')] !== 'undefined') {
            value = this.getModel()[attribute.replace(':date', '')]
          }
          if (value) {
            value = this.$moment(new Date(value)).format('DD.MM.Y')
          }
        } else {
          if (this.getRawData() && typeof this.getRawData()[attribute] !== 'undefined') {
            value = this.getRawData()[attribute]
          } else if (this.getModel() && typeof this.getModel()[attribute] !== 'undefined') {
            value = this.getModel()[attribute]
          }
          try {
            value = JSON.parse(value)
          } catch (e) {

          }
          if (value instanceof Array) {
            value = value.map(item => item.name).join(',')
          }
        }

        result = result.replace(`{{${attribute}}}`, value ?? '')
      })

      return result
    }
  }
}
</script>

<style src="../../CKEditor.css">

</style>
